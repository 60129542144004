.attendanceMainDiv {
  width: 100% !important;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding-top: 3%; */
  /* New Style*/
  height: 100vh;
  background-color: lightyellow;
}
.attendanceMainDiv .MuiFormControl-root {
  width: 70% !important;
}

.attendanceMainDiv .attendanceFormControl {
  background-color: #cdccb7;
  width: 90%;
  margin: 2% auto;
  padding-bottom: 1%;
  border-radius: 10px;
}

.attendanceSubmit {
  background-color: #cdccb7;
}

.attendanceSubmit:hover {
  background-color: #949386;
}

.parentDiv {
  display: flex;
  width: 100%;
  height: 90vh;

  /* New Style*/
  height: 100% !important;
}

/* ANOTHER STYLE TO MAKE AN ATTENDENCE PAGE */
.div_1 {
  flex: 1;
  /* background-color: red; */

  /* New Style*/
  height: 87% !important; /* was 100% before update v3.1*/
}

.div_2 {
  flex: 0.4;
  /* background-color: blue; */
  border-left: 1px solid gray;
  background-color: white;
  height: 90vh;

  /* New Style*/
  height: 100% !important;
}

.rightSide {
  border-top: 1px solid gray;
  margin-top: 25px;
  background-color: white;
  padding: 10% 5%;
  text-align: right;
  direction: rtl;
}

.rightSide p {
  cursor: pointer;
  text-align: center;
}

.rightSide p:hover {
  background-color: #e2ecff;
  color: white;
}

.BoxTaskDiv {
  display: flex;
  height: 80vh;
  direction: rtl;
  margin: 0 5%;

  /*new Style*/
  height: 75% !important;
}

.TaskMainDiv {
  flex: 1;
  height: 28%;
  border: 1px solid gray;
  margin: 2%;
  border-radius: 5px;
  /* padding: 50px 0; */
  display: flex;
  justify-content: center !important;
  flex-direction: column;
  align-content: center;
  align-items: center;
  vertical-align: bottom;
}

.TaskMainDiv p {
  text-align: center !important;
  margin: 0;
  color: #fefcfc;
  padding-top: 15px;
  cursor: pointer;
}

.TaskMainDiv:hover {
}

.homeTasks1 {
  background-color: #248888;
}
.homeTasks2 {
  background-color: #2e94b9;
}

.reportTask1 {
  background-color: #00cfdd;
}
.reportTask2 {
  background-color: #fdac41;
}
.reportTask3 {
  background-color: #475f7b;
}

.orderTasks1 {
  background-color: #d25565;
}
.orderTasks2 {
  background-color: #2e94b9;
}
.orderTasks3 {
  background-color: #248888;
}
.manageTasks1{
  background-color: #475f7b;
}
.manageTasks2{
  background-color: #475f7b;  
}

.hiddenTask {
  opacity: 0.3;
}

.reportIcon {
  font-size: xx-large;
  background-color: white;
  border-radius: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 50px;
  height: 35px;
}

.chosenList {
  color: #e7c9ab;
}

.breadCrumbDir {
  direction: rtl;
}

.logoDivAt {
  width: 100%;
  background-color: white;
}

.topOfAt {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  direction: rtl;

  /* New Style */
  height: 25% !important;
}

.grid-item-At {
  padding: 20px;
  font-size: 20px;
  text-align: center;
}

.profileImageAt {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profileDivAt {
  display: inline;
  padding: 0 5%;
}

@media only screen and (max-width: 800px) {
  .parentDiv {
    display: flex;
    flex-direction: column-reverse !important;
    width: 100%;
    height: 90vh;
    /* New Style*/
    height: 100% !important;
  }

  .div_2 {
    display: flex;
    flex: 0.1 0;
    flex-direction: row;
    border: none !important;
  }

  .rightSide {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    padding: 0 !important;
    margin: 0;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    border: none !important;
  }

  .rightSide p {
    margin: 0;
  }

  .logoDivAt {
    flex: 0;
  }

  .logoDivAt img {
    width: 90px !important;
    height: 90px !important;
    margin: 10px !important;
  }
}

@media only screen and (max-width: 600px) {
  .BoxTaskDiv {
    flex-direction: column !important;
    margin: 0 7.5%;
  }
}

.employeeList {
  width: 50% !important;
  margin: auto !important;
  margin-bottom: 2% !important;
}


/* Edit hours page (Model Box) */
.editHours{
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  align-content: flex-end !important;
  align-items: flex-end !important;
}

.editHours label,input{
  width: 35%;
  font-size: 14px !important;
  margin: 10px;
  text-align: center;
}

.editHoursChildren{
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
}

.editHoursChildren .css-1rv8zyt-MuiStack-root{
  padding-top: 0px !important;
}
.editHoursChildren .MuiFormControl-root{
  width: 100%;
}