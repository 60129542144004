.reportDiv {
  padding-top: 15%;
}

.projectSelect {
  text-align: center;
}

.noBookingNoSold {
  margin-top: 5% !important;
  text-align: center;
}
