.projINFO .grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    "1  2"
    "3  4"
    "5 5";
}
.projINFO .grid-item {
  text-align: center;
}

.projINFO {
  cursor: pointer;
}

.newBooking {
  width: 35%;
  margin: auto;
  margin-bottom: 5px;
  border-radius: 10px;
  color: rgb(51, 57, 73);
}
.newBooking:hover {
  color: rgb(0, 80, 0);
}

.bookingProjects div.card-body {
  padding: 5% 0%;
  border: solid gray 1px;
  position: relative;
  top: -4px;
  width: 100%;
  margin: 0;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: none;
  background-color: #212121;
  color: white;
}
.bookingProjects div.card-body p {
  text-align: center;
}

.bookingProjects {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  position: inherit;
  margin: 0 0;
  margin: auto;
  margin-top: 20px;
  /* margin-left: 5%;
    margin-right: 5%; */
  justify-content: center;
}
