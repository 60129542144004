.bookingDataEdite .addBookingTab {
  width: 100%;
}

/* @media only screen and (min-width: 959px) {
  .bookingDataEdite .MuiBox-root-72 {
    top: 55%;
    width: 60%;
    height: auto;
  }
} */

@media only screen and (min-width: 1200px) {
  .bookingDataEdite .MuiBox-root-78 {
    top: 55%;
    width: 60% !important;
    height: auto;
  }
}

@media only screen and (max-width: 685px) {
  .bookingDataEdite .MuiTabPanel-root {
    padding: 24px 3px;
  }
}

@media only screen and (max-width: 570px) {
  .bookingDataEdite .addBookingTab {
    width: 100%;
  }
}

@media only screen and (max-width: 510px) {
  .bookingDataEdite .MuiBox-root-72 {
    padding: 16px 15px 24px;
  }
}

@media only screen and (max-width: 480px) {
  .bookingDataEdite .MuiBox-root-348 {
    width: 90%;
  }
}

@media only screen and (max-width: 470px) {
  .bookingDataEdite .MuiTabPanel-root {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 460px) {
  .bookingDataEdite .MuiBox-root-348 {
    width: 100%;
  }
}

@media only screen and (max-width: 426px) {
  .bookingDataEdite .MuiBox-root-72 {
    width: 95% !important;
  }
}

@media only screen and (max-width: 410px) {
  .bookingDataEdite .MuiBox-root-348 {
    padding: 0;
  }
}

@media only screen and (max-width: 390px) {
  .bookingDataEdite .MuiBox-root-72 {
    width: 99% !important;
  }
  .bookingDataEdite .MuiBox-root-72 {
    padding: 16px 3px 24px;
  }
}

@media only screen and (max-width: 380px) {
  .bookingDataEdite .MuiTabPanel-root {
    padding: 18px 10px !important;
  }
}

.bookingDataEdite .MuiInputLabel-filled.MuiInputLabel-shrink {
  padding-right: 10px;
  padding-top: 5px;
}

.bookingDataEdite .MuiInputLabel-formControl {
  right: 0;
}

.save {
  background-color: green !important;
}

.cancel {
  background-color: red !important;
}

.myBookingData .appartmentOnFloor {
  width: 20%;
}
.myBookingData .floor {
  width: 20%;
}

.myBookingData .floor .MuiInputBase-input,
.myBookingData .appartmentOnFloor .MuiInputBase-input {
  text-align: center !important;
}

@media only screen and (max-width: 860px) {
  .myBookingData .appartmentNumber {
    width: 100%;
  }
  .myBookingData .floor {
    width: 47%;
  }
  .myBookingData .appartmentOnFloor {
    width: 47%;
  }
}

@media only screen and (max-width: 550px) {
  .myBookingData .sizeField {
    width: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .myBookingData .depositReturn {
    width: 50%;
  }

  .myBookingData .price {
    width: 35%;
  }

  .myBookingData .VAT {
    width: 65%;
  }

  .myBookingData .total {
    width: 100%;
  }
}

.ID_INS_button {
  display: flex;
  width: 50%;
  margin: auto;
  padding: 10px;
}

.ID_INS_button button {
}

.mainBoxINFO {
  top: 55%;
  height: 75%;
  max-width: 950px !important;
}

.bookingDataEdite .addBookingTab {
  height: 80%;
}

.bookingINFO {
  height: 85%;
  overflow-y: scroll;
}
