.tableExcelDiv {
  margin-top: 5%;
  padding-bottom: 5%;
}

.tableExcelDiv table {
  text-align: center;
  border-radius: 10px;
  width: 90% !important;
  margin: auto;
  margin-top: 10%;
  background-color: white;
  font-family: arial, sans-serif;
  font-size: 16px;
}

.tableExcelDiv td,
.tableExcelDiv th {
  border: 1px solid gray;
  border-collapse: collapse;
}

.tableExcelDiv th {
  background-color: black;
  color: white;
}

.MuiDataGrid-row:nth-child(even) {
  background-color: #eee;
}
.MuiDataGrid-row:nth-child(odd) {
  background-color: #fff;
}

.buttonBooking {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 40px;
  /* width: 50%; */
  /* margin: auto !important; */
  margin-top: 6%;
  display: inline;
}

.buttonBooking button {
  width: 30%;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.buttons {
  width: 100%;
  height: 5vh;
  position: relative;
}

.submit {
  float: right;
}

.next {
  position: absolute;
  background: red;
  color: white;
  top: 10px;
  left: 0px;
}

.past {
  position: absolute;
  background: red;
  color: white;
  top: 10px;
  right: 0px;
}

.tableExcelDiv .form-control {
  border-radius: 0% !important;
}

.state {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addBooking {
  width: 60%;
  margin: auto;
  margin-top: 5%;
}

.addBooking .MuiFormControl-root {
  width: 80%;
  margin: 0 auto;
}

.dataTableDiv {
  width: 80% !important;
  margin: auto;
  margin-top: 5%;
  background-color: white;
}

.MuiDataGrid-columnsContainer {
  background-color: gray !important;
  color: white !important;
}

.MuiInputLabel-formControl {
  left: unset !important;
}
.MuiSelect-icon {
  right: unset !important;
  left: 0;
}

.MuiTablePagination-input .MuiSelect-icon {
  right: 0 !important;
  left: unset !important;
}

/* delter footer of table */
/* MuiDataGrid-footerContainer */

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
  text-align: center !important;
}

.MuiSelect-select.MuiSelect-select {
  text-align: right;
  padding-right: 0 !important;
}

.materialTableDiv {
  width: 80% !important;
  margin: auto;
  margin-top: 5%;
  background-color: white;
}

.MuiInputLabel-shrink {
  transform: translate(0, 1.5px) scale(1) !important;
  font-size: 13px !important;
}

.MuiFormControl-root {
  width: 50%;
  margin: 2% 0 !important;
  padding: 0 1% !important;
  direction: rtl;
}

.addBookingTab {
  width: 80%;
  margin: auto;
  margin-top: 3%;
  background-color: rgb(255, 255, 255);
  height: auto;
  direction: rtl;
  border-radius: 10px;
}

.addBookingTab1 {
  width: 80%;
  margin: auto;
  margin-top: 5% !important;
  background-color: rgb(255, 255, 255);
  height: auto;
  direction: rtl;
  border-radius: 10px;
}

.MuiBox-root-75 {
}

.tabsContent {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  justify-content: flex-start !important;
  align-items: center !important;
  align-content: space-around !important;
}

.MuiTabs-flexContainer {
  justify-content: center;
}

.phoneField input {
  direction: ltr !important;
}

/* .MuiTabPanel-root {
  padding: 0px !important;
} */

.MuiBox-root .MuiBox-root-69 {
  width: 80%;
  margin: auto;
  border-radius: 10px;
  background-color: white;
}

.tabList1 {
  width: 80%;
  margin: auto;
  background-color: white;
  border-radius: 10px;
}

.tabPanel {
  padding: 0px !important;
}

.sizeField .MuiFormHelperText-filled,
.IDField .MuiFormHelperText-filled {
  text-align: right !important;
}

@media only screen and (max-width: 433px) {
  .addBookingTab {
    width: auto !important;
  }
}

.searchDiv {
  width: 100%;
}

.searchDiv .MuiFormControl-root {
  margin: 0 !important;
  width: 100%;
}

/* .MuiFormControl-root {
  width: 20% !important;
  margin: 0 !important;
} */

.MuiGridPanel-root .MuiGridPanel-root {
  width: 50%;
}

.MuiGridPanel-root .MuiGridFilterForm-root {
  justify-content: flex-start !important;
}

.MuiGridPanel-root .MuiGridPanel-paper {
  flex-direction: column !important;
  justify-content: flex-start !important;
}
.MuiGridPanel-root .MuiGridFilterForm-filterValueInput {
  width: 150px !important;
}
.MuiGridPanel-root .MuiGridFilterForm-operatorSelect {
  width: 150px !important;
}

.MuiGridPanel-root .MuiFormControl-root {
  width: auto;
  justify-content: space-evenly;
}

.tableExcelDiv .appartmentOnFloor {
  width: 20%;
}
.tableExcelDiv .floor {
  width: 20%;
}

.slash {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  justify-content: center;
  align-items: center;
  margin: 2% 0;
  margin-bottom: 0;
  padding: 1%;
  padding-bottom: 0;
}

.tableExcelDiv .floor .MuiSelect-select.MuiSelect-select,
.tableExcelDiv .appartmentOnFloor .MuiSelect-select.MuiSelect-select {
  text-align: center;
}

@media only screen and (max-width: 860px) {
  .tableExcelDiv .appartmentNumber {
    width: 100%;
  }
  .tableExcelDiv .floor {
    width: 47%;
  }
  .tableExcelDiv .appartmentOnFloor {
    width: 47%;
  }
}

@media only screen and (max-width: 550px) {
  .tableExcelDiv .sizeField {
    width: 60%;
  }
}
