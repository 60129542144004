.resetPass:hover {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.swal-text {
  text-align: center;
}

@media only screen and (max-width: 660px) {
  .Login-main {
    width: 85% !important;
  }
}

.mainBoxLogin {
  height: 25%;
  max-width: 950px !important;
  border-radius: 10px !important;
}

.resetButtonDiv {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
  margin-top: 10%;

  border-radius: 10px;
}

.resetButton {
  width: max-content;
  margin: auto;
  background-color: rgb(200, 200, 199) !important;
}

.resetButton:hover {
  background-color: #198647 !important;
  color: white !important;
}

@media only screen and (max-width: 660px) {
  .mainBoxLogin {
    width: 60% !important;
  }
}

@media only screen and (max-width: 450px) {
  .mainBoxLogin {
    width: 75% !important;
  }
}
