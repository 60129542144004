.rep_tableDiv {
  text-align: center;
  margin: auto;
  margin-top: 5%;
}

.rep_table {
  direction: rtl;
  width: 70% !important;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  border: unset !important;
}

.rep_tableFloor {
  background-color: initial !important;
  border-radius: unset !important;
  width: 20%;
}

.rep_table th {
  background-color: white;
}

.rep_tableFloor {
  border: unset !important;
}

.rep_tableDiv .hovertext {
  position: relative;
}

.rep_tableDiv .hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(232, 235, 200);
  color: black;
  text-align: center;
  /* border-radius: 2px; */
  padding: 12% 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1%;
  left: 0px;
  top: 0%;
  text-align: center;
  vertical-align: middle;
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rep_tableDiv .hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}

@media only screen and (max-width: 807px) {
  .rep_tableFloor {
    width: 20%;
  }
  .rep_table {
    width: 90% !important;
  }
}

@media only screen and (max-width: 649px) {
  .rep_tableFloor {
    width: 24%;
  }
}

@media only screen and (max-width: 538px) {
  .rep_tableFloor {
    width: 27%;
  }
}

@media only screen and (max-width: 461px) {
  .rep_tableFloor {
    width: 29%;
  }
}

@media only screen and (max-width: 437px) {
  .rep_tableFloor {
    width: 30% !important;
  }
}

@media only screen and (max-width: 420px) {
  .rep_tableFloor {
    width: 32% !important;
  }
}

@media only screen and (max-width: 391px) {
  .rep_tableFloor {
    width: 34% !important;
  }
}

@media only screen and (max-width: 391px) {
  .rep_tableFloor {
    width: 34% !important;
  }
  .rep_table {
    width: 95% !important;
  }
}

.rep_tableDiv hr {
  width: 75%;
  margin: auto;
}

.rep_tableDiv .buildingName {
  margin: 2% 0 !important;
}

.rep_tableDiv .projectName {
  font-size: 120% !important;
}
