.orderHomeDiv {
  padding-top: 15%;
  direction: rtl;
  margin: 0 7%;
}

.orderHomeDiv h4 {
  text-align: center;
}

.distractDiv .MuiInputLabel-filled.MuiInputLabel-shrink {
  padding-right: 0;
  padding-top: 5px;
}

.distractDiv .MuiInputLabel-formControl {
  right: 20px !important;
}

.distractDiv .MuiInputLabel-filled {
  transform: translate(12px, 7px) scale(1) !important;
}
