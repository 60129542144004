.dashboard-singleProject img {
  width: 200px;
  height: 130px;
  margin: auto;
}

.dashboard-singleProjectInfo {
  width: 30%;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: center;
  margin: auto;
  text-align: center;
}

.dashboard-singleProject {
  padding-top: 100px;
  justify-content: center;
  width: 90%;
  margin: auto;
}

.dashboard-singleProject label {
  margin: 0 5px;
}

.dashboard-singleProject input {
  margin: 5px;
  border: solid gray 1px;
  border-radius: 5px;
  text-align: center;
}

.each-building {
  width: 100%;
  height: 100%;
  border: solid gray 1px;
  text-align: center;
  border-radius: 5px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none !important;
  color: black;
}

.each-building p {
  width: 100%;
  margin: 0;
  text-align: center;
  justify-content: center;
  direction: rtl;
}

.each-building:hover {
  background-color: #2d6a4f;
  color: white !important;
}

.div-eachProject {
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  position: inherit;
  margin: auto;
  /* margin-left: 5%;
    margin-right: 5%; */
  justify-content: center;
}

a:focus {
  background-color: #2d6a4f;
  color: white !important;
}

/* .buildings {
    height: auto;
    display: flex;
    flex-wrap: wrap;
    position: inherit;
    margin: auto;
     margin-left: 5%;
    margin-right: 5%; 
    justify-content: center;
  } */

.div-addBuilding {
  width: 15%;
  height: 50%;
  border: gray solid 1px;
  background-color: white;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 10px;
  cursor: pointer;
}

.div-addBuilding:hover {
  color: white;
  background-color: rgb(0, 104, 69);
}

.div-addBuilding p {
  width: 100%;
  text-align: center;
  align-items: center;
  margin: 0;
}

.deleteBuilding {
  cursor: pointer;
  color: black;
  position: relative;
  bottom: 15px;
  left: -5px;
  width: 10px;
}

.deleteBuilding:hover {
  color: red;
}

.div-building {
  width: 15%;
  height: 50%;
  margin: 10px;
}

.buildingUpdateButton {
  width: 80px;
  margin: auto;
}

.update-button {
  width: 100%;
  height: 50px;
  border: solid 1px gray;
  border-radius: 5px;
  margin-bottom: 10%;
  cursor: pointer;
  background-color: white;
}

.update-button:hover {
  background-color: rgb(51, 57, 73);
  color: white;
}

.uploadDiv {
  margin-top: 0%;
}

h5 {
  margin: 0 !important;
}
