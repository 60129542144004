.search_exportDiv {
  display: flex;
  justify-content: space-between;
}

.search_exportDiv button {
  background-color: rgb(0 0 0 / 31%) !important;
  color: black !important;
}

.search_exportDiv button:hover {
  background-color: rgb(23, 82, 23) !important;
  color: white !important;
}

.mainBoxINFOAlwaqil {
  height: 75%;
  /* max-width: 950px !important; */
}

.contractText {
  font-size: 12.5px !important;
}

.contractText:hover {
  cursor: pointer;
  color: blue !important;
}

.deleteContract:hover {
  color: red !important;
}

.createContract:hover {
  color: green !important;
}
