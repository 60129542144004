.settingDiv {
  padding-top: 10% !important;
  width: 80%;
  margin: auto;
  /* background-color: white; */
}

.updatePassword {
  background-color: white;
  margin-top: 10%;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.updatePassword .MuiFormControl-root {
  width: 80% !important;
}

.updateButton {
  width: auto !important;
}

@media only screen and (max-width: 700px) {
  .settingDiv {
    padding-top: 15% !important;
  }
}

@media only screen and (max-width: 500px) {
  .settingDiv {
    padding-top: 20% !important;
  }
}
