.grid-container-images {
  display: grid;
  grid-template-columns: auto auto auto auto;
  text-align: center;
  align-items: center;
  border: solid gray 1px;
}

.grid-item-images {
}

.imageInImagesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageInImages {
  width: 115px !important;
  height: 92px !important;
  margin: 5px auto !important;
}

.imageURLInImages {
  border-left: solid gray 1px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageURLInImages input {
  height: 100%;
  width: 100% !important;
  border: hidden !important;
  background-color: initial;
  border-radius: 0px !important;
  margin: 0 0 !important;
}

.imageNumberInImages {
  border-left: gray solid 1px;
  padding: 5px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteImage {
  border-right: gray solid 1px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.bG {
  background-color: rgb(224, 224, 224) !important;
}

.bW {
  background-color: rgb(245, 245, 245) !important;
}
