.attendanceReportDiv {
  width: fit-content;
  margin: auto;
  background-color: white;
}

.attendanceReportDiv thead {
  background-color: rgb(12, 73, 73);
  color: white;
}

.attendanceReportDiv table {
  margin: auto;
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.attendanceReportDiv td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

.attendanceReportDiv tr:nth-child(even) {
  /* background-color: #dddddd; */
}

.attendanceReportDiv .gray {
}

.attendanceReportDiv .red {
  background-color: rgb(129, 0, 0) !important;
  color: white !important;
}
