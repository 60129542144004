
.contractDiv {
  padding: 3%;
  direction: rtl;
  font-family: 'Sakkal Majalla' !important;
  /* font-size: 12pt !important; */
  background-color: white !important;
  padding-top: 240px;
}

.paned_8 {
  padding-top: 295px;
}

.contractDiv h3 {
  text-align: center;
}
.contractDiv h3:last-child() {
  text-decoration: underline;
}

.contractDiv h3,
p,
pre {
  margin: 0 0;
}

.p1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.p1 input {
  width: auto;
  background-color: initial;
  border: none;
  padding-right: 10px;
  color: red !important;
}

.day {
  width: 50px !important;
}

.date {
  width: 90px !important;
  text-align: center;
  padding: 0px !important;
}

.number {
  width: 100px !important;
}

.name {
  width: 18.5% !important;
  padding: 0px !important;
}

.nationality {
}

.nationality {
  width: 60px !important;
  padding: 0px !important;
}

.number {
  width: 10ch !important;
  padding: 0 0 !important;
  text-align: center;
}

.apartment {
  width: 4ch !important;
  padding: 0px !important;
  text-align: center;
}

.direction {
  width: 13ch !important;
  padding: 0px !important;
  text-align: center;
}

.floor {
  width: 11ch !important;
  padding: 0px !important;
  text-align: center;
}

.size {
  width: 7ch !important;
  padding: 0px !important;
  text-align: center;
}

.landNumber {
  width: 4ch !important;
  padding: 0px !important;
  text-align: center;
}

.scheme {
  width: 15ch !important;
  padding: 0px !important;
  text-align: center;
}

.district {
  width: 8ch !important;
  padding: 0px !important;
  text-align: center;
}

.instrument {
  width: 12ch !important;
  padding: 0px !important;
  text-align: center;
}

.n1 {
  width: 2ch !important;
  padding: 0px !important;
  text-align: center;
}

.priceNumbers {
  width: 9ch !important;
  padding: 0px !important;
  text-align: center;
}

.priceWords {
  width: 33ch !important;
  padding: 0px !important;
  text-align: center;
}

.checkNumber {
  width: 8ch !important;
  padding: 0px !important;
  text-align: center;
}

.bankName {
  width: 7ch !important;
  padding: 0px !important;
  text-align: center;
}

.type {
  width: 15ch !important;
  padding: 0px !important;
  text-align: center;
}

.years {
  width: 7ch !important;
  padding: 0px !important;
  text-align: center;
}

.years2 {
  width: 10ch !important;
  padding: 0px !important;
  text-align: center;
}

.t12 {
  display: flex;
  direction: rtl;
  justify-content: flex-start;
  padding-bottom: 37.8px;
}

.t2 {
  margin-right: 41% !important;
}

.shahed {
  direction: rtl !important;
  text-align: right !important;
}

pre {
  border: none !important;
}

.numbering {
  padding-left: 23px !important;
}

.indentText {
  padding-right: 37px !important;
  text-indent: -34px !important;
}

.inputSpan {
  color: red !important;
}

.contractHeader {
  padding-bottom: 2% !important;
}

pre {
  font-size: 75.5% !important;
}

.shahed_1 {
  padding-bottom: 37.8px;
}

.signature {
  padding-bottom: 37.8px;
}

.NoSpaceAfter {
  margin-bottom: 0px !important;
}

.blackPrint {
  color: black !important;
}
