.Test {
    height: auto !important;
    top: 60% !important;
}

.TestDive {
    display: flex;
    justify-content: flex-start; /* Aligns items to the start of the container */
    align-items: center; /* Vertically centers the items */
  }
  
  .btn-primary {
    margin-right: auto; /* Pushes all other items to the right */
    background-color: #007bff; /* Bootstrap primary button color */
    border-color: #007bff;
    color: white;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    line-height: 1.5;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: #0056b3; /* Darkens the button on hover */
    border-color: #004085;
  }
  
  .TabList {
    display: flex;
    flex-grow: 1; /* Allows the tab list to take up the remaining space */
    justify-content: flex-end; /* Aligns tabs to the end of the container */
    border-bottom: 2px solid #ff0000; /* Red line below the tabs */
  }
  
  .TabList .Tab {
    margin-left: 20px; /* Spacing between tabs */
  }
  
.cancel {
  position: absolute;
  background: red;
  color: white;
  top: 10px;
  right: 0px;
}