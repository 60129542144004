.mainBoxCreateContract {
  height: 75%;
  max-width: 950px !important;
  overflow-y: overlay;
  direction: rtl;
  border-radius: 2px;
}

.contractHeader {
  text-align: center;
}

.FirstPersonContract,
.priceWordContract,
.clientNameContract {
  width: 100% !important;
}

.dateEnContract,
.warrantyContract {
  width: -webkit-fill-available !important;
}
