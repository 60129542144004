.bgimgLanding {
  background-image: url('https://images.unsplash.com/photo-1511452885600-a3d2c9148a31?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzN8fHJlYWwlMjBlc3RhdGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60');
  height: 100vh;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
}

.topleftLanding {
  position: absolute;
  top: 0;
  left: 16px;
}

.bottomleftLanding {
  position: absolute;
  bottom: 0;
  left: 16px;
}

.middleLanding {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.bgimgLanding hr {
  margin: 10% auto;
  width: 90%;
}

.bgimgLanding p {
  margin-top: unset !important;
  margin-bottom: unset !important;
  text-align: center;
}

.topLanding {
    position: absolute;
    top: 10%;
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-content: space-between;
    justify-content: flex-start;
    align-items: center;
    margin: 1%;
}

.topLandingItems p {
  direction: rtl;
}

.topLandingItems{
  width: fit-content;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center  !important;
  border: gray groove 1px !important;
  border-radius: 10px;
  margin: 2%;
  cursor: pointer;
  padding: 10px;
}

.topLandingItems{
  /* background-color: #4ea8de; */
  font-size: 3vw;
	margin: max(1rem, 3vw);
	border: 0.35rem solid;
	padding: 3vw;
	border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: "borderRotate" var(--d) linear infinite forwards;
}

.topLandingItems {
  --border-width: 3px;
  position: relative;
  color: white;
  background: #222;
  border-radius: var(--border-width);
}

.topLandingItems::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    60deg,
    hsl(224, 85%, 66%),
    hsl(269, 85%, 66%),
    hsl(314, 85%, 66%),
    hsl(359, 85%, 66%),
    hsl(44, 85%, 66%),
    hsl(89, 85%, 66%),
    hsl(134, 85%, 66%),
    hsl(179, 85%, 66%)
  );
  animation: moveGradient 4s alternate infinite;
}


@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
