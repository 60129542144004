.projFigure {
}

.projFigureDetails {
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  direction: rtl;
  font-size: 20px;
  flex-wrap: wrap;
}

/* .MuiSvgIcon-root; */

.projFigureTitle {
  text-align: center;
  font-size: 20px !important;
}
