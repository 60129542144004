.time {
  width: 100%;
}

.time svg {
  margin: 0 5px;
  font-size: 35px;
  text-transform: capitalize;
  font-weight: 600;
  width: 20px;
  height: 20px;
}

.time p {
  margin: 0 !important;
  text-transform: capitalize;
  font-size: 20px;
  text-align: center;
  direction: rtl;
  padding: 10px 0;
}
