.mybookingDiv p {
  margin-bottom: 0 !important;
}

.mybookingDiv {
  padding-top: 10%;
}

.radioButtonDiv {
  width: 80%;
  margin: auto;
}

.radioButtonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  margin-top: 10%;
}

.buttonBooking button {
  width: 30%;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.mybookingDiv table {
  text-align: center;
  border-radius: 10px;
  width: 90% !important;
  margin: auto;
  background-color: white;
  font-family: arial, sans-serif;
  font-size: 16px;
}

.updateBooking {
  width: 30%;
  margin: auto;
  text-align: center;
}

.buttonBookingUpdate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 50%;
  margin: auto;
  margin-top: 5%;
}

.buttonBookingUpdate button {
  width: 100%;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
}

.selectProjectDiv,
.selectAppartmentDiv,
.selectAppartmentStateDiv {
  margin-top: 2%;
}

.state_bank_div .MuiFormControl-root {
  width: 100%;
  direction: rtl;
}

.bankNameDiv .MuiFormControl-root {
  margin: 5% 0;
  direction: ltr;
}

.bankNameDiv .MuiFormControl-root input {
  text-align: center;
}

.MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
  font-weight: bold;
  resize: horizontal;
  overflow-x: overlay;
  overflow-y: hidden;
  border-right: 1px solid;
}

.uploadDiv {
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: auto;
  margin-top: 5%;
}

.MuiDropzoneArea-textContainer p {
  text-align: center;
}
.uploadDiv button {
  width: 100%;
  margin: auto;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 5%;
}

.MuiDataGrid-root .MuiDataGrid-cell--withRenderer {
  display: unset !important;
}

@media only screen and (max-width: 550px) and (min-width: 360px) {
  .mybookingDiv {
    margin-top: 5%;
  }
}

@media only screen and (max-width: 360px) and (min-width: 100px) {
  .mybookingDiv {
    margin-top: 10%;
  }
}

.booking {
  margin-right: 15px !important;
  margin: 0%;
  float: right;
}
.booking:hover,
.setting:hover {
  cursor: pointer;
  color: red;
}

.setting {
  margin-right: 15px !important;
  margin: 0%;
  float: right;
}

.mybookingDiv .noteMybooking {
  text-align: right;
  direction: rtl;
  margin-right: 10px;
  font-size: 80%;
  color: #a02222;
  width: 80% !important;
  margin: auto;
  margin-top: 10px;
}

@media only screen and (max-width: 500px) {
  .dataTableDiv {
    margin-top: 12% !important;
  }
}

.edit_delte_buttons button {
  margin: 2px !important;
}

.MuiDataGrid-cell {
  direction: rtl !important;
}

.edit_delte_buttons button {
  background-color: rgb(0, 90, 0);
}

.edit_delte_buttons button:hover {
  background-color: rgb(16, 143, 16);
}

.edit_delte_buttons button:first-child {
  background-color: rgb(0, 90, 0);
}

.edit_delte_buttons button:first-child:hover {
  background-color: rgb(16, 143, 16);
}

.edit_delte_buttons button:last-child {
  background-color: rgb(90, 0, 0);
}

.edit_delte_buttons button:last-child:hover {
  background-color: rgb(161, 37, 37);
}

@media only screen and (max-width: 2700px) {
  .showAllDataButton {
    width: 10% !important;
  }
}

@media only screen and (max-width: 1167px) {
  .showAllDataButton {
    width: 15% !important;
  }
}

@media only screen and (max-width: 830px) {
  .showAllDataButton {
    width: 20% !important;
  }
}

@media only screen and (max-width: 661px) {
  .showAllDataButton {
    width: 25% !important;
  }
}

@media only screen and (max-width: 564px) {
  .showAllDataButton {
    width: 35% !important;
  }
}

@media only screen and (max-width: 450px) {
  .showAllDataButton {
    width: 45% !important;
  }
}

@media only screen and (max-width: 387px) {
  .showAllDataButton {
    width: 53% !important;
  }
}
