.homepage {
  padding-top: 10%;
}

.prjectselector {
  width: 20%;
  margin: auto;
  margin-top: 5%;
  text-align: center;
}

.buildingSelector {
  width: 20%;
  margin: auto;
  text-align: center;
  margin-top: 3%;
}

@media only screen and (max-width: 600px) {
  .homepage {
    padding-top: 15%;
  }
}

@media only screen and (max-width: 400px) {
  .homepage {
    padding-top: 18%;
  }
  .prjectselector,
  .buildingSelector {
    width: 40%;
  }
  .prjectselector {
    margin-top: 15%;
  }
}

.myList {
  margin-right: 15px !important;
  margin: 0%;
  float: right;
}
.myList:hover,
.setting:hover {
  cursor: pointer;
  color: red;
}

.setting {
  margin-right: 15px !important;
  margin: 0%;
  float: right;
}
