.addBookingDiv {
  padding-top: 15%;
  width: 75%;
  margin: auto;
}

.addBookingDiv h5 {
  text-align: center;
}

.dataTableDiv {
}

.buildingOption {
  width: 20% !important;
  margin: auto;
}

@media only screen and (max-width: 460px) {
  .MuiTabPanel-root {
    padding: 18px !important;
  }

  .addBookingDiv {
    width: 100%;
  }
}

@media only screen and (max-width: 770px) {
  .buildingOption {
    width: 80%;
  }
}

@media only screen and (max-width: 700px) {
  .buildingOption {
    width: 25% !important;
  }
}

@media only screen and (max-width: 540px) {
  .buildingOption {
    width: 30% !important;
  }
}

@media only screen and (max-width: 350px) {
  .buildingOption {
    width: 40% !important;
  }
}
