.previlage .MuiFormControl-root .MuiFormGroup-root {
  flex-direction: row !important;
  width: 80%;
  margin: auto;
  justify-content: center;
}

.previlage .MuiFormControl-root {
  width: 100%;
}

.datagridManageUsers {
  width: 80%;
  margin: auto;
}

.addUser .MuiFormHelperText-root {
  text-align: right !important;
}

.MuiBox-root-83 {
  border-radius: 10px;
}

.edit_delte_buttons {
  cursor: pointer;
}

.topManageUser {
  padding: 2%;
  text-align: right;
  direction: rtl;
}

.topManageUser button {
  font-size: 100%;
}

.manageUsers {
  padding-top: 5%;
}

@media only screen and (max-width: 1440px) {
  .manageUsers {
    padding-top: 6%;
  }
}

@media only screen and (max-width: 1200px) {
  .manageUsers {
    padding-top: 8%;
  }
}

@media only screen and (max-width: 1000px) {
  .manageUsers {
    padding-top: 6%;
  }
}

@media only screen and (max-width: 700px) {
  .manageUsers {
    padding-top: 8%;
  }
}

@media only screen and (max-width: 550px) {
  .manageUsers {
    padding-top: 10%;
  }
}

@media only screen and (max-width: 470px) {
  .manageUsers {
    padding-top: 14%;
  }
}
