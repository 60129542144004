.building {
  /* width: 60%;
  margin: auto;
  border: gray solid 1px;
  border-radius: 5px;
  background-color: rgb(211, 207, 207);
   margin: 5px; 
  margin-bottom: 10px;
  margin-top: 10px; */

  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 5px auto;
  /* justify-content: center; */
  flex-direction: row-reverse;
}

.div-room {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  width: 60%;
}

.center {
  /* text-align: center;
  width: 100%;
  margin: auto; */
}

.div-details {
  width: 100%;
  margin: auto;
  margin-top: 5%;
}

.div-details p {
  text-align: center;
  margin-bottom: 0 !important;
}

.div-buildingDetails {
  float: right;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.div-buildingDetails p {
  margin: 0;
  cursor: pointer;
}

.details {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  width: 100%;
  margin: auto;
  justify-content: center;
}

.details input {
  width: 100%;
}

.details-ar {
  width: 45%;
  margin: 2.5%;
}

.details-en {
  width: 45%;
  margin: 2.5%;
}

.addDetailsButton {
  width: 15%;
  height: 30px;
  margin: auto;
  margin-bottom: 5%;
  text-align: center;
  border: solid gray 1px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer;
}

.div-state {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  width: 30%;
}

.div-img {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  width: 40%;
}
.div-img input {
  width: 100%;
}

.div-order {
  float: right;
  display: flex;
  flex-direction: row-reverse;
  text-align: center;
  align-items: center;
  width: 30%;
  justify-content: center;
}

.div-order input {
  width: 30%;
}

.div-images p {
  text-align: center;
  margin: 0;
}

.div-images {
  /* width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center; */
  width: 100%;
  margin: auto;
}

.buildingImages {
}

.div-allImage {
  width: 100%;
  float: right;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row-reverse; */
  flex-direction: column;
}

.div-inputImage {
  width: 100%;
  margin: 5% auto;
}

.div-inputImage {
  /* width: 75%;
  margin: auto;
  justify-content: center; */
}

.inputImage {
  width: 100% !important;
  padding: 5px;
  margin: auto !important;
  border-radius: 0px !important;
}

.addImage {
  border-radius: 5px;
  border: solid gray 1px;
  width: auto;
  padding: 5px;
}

.addImage:hover {
  color: white;
  background-color: rgb(0, 104, 69);
}

.eachImage {
  /* float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center; */
  width: 80%;
  margin: auto;
  direction: rtl;
}

.eachImage p {
  width: 20%;
  margin: 5px 10px;
}

.eachImage label,
input {
  margin: 5px;
}

.eachImage input {
  width: 75%;
}

.deleteImage {
  color: black;
  cursor: pointer;
}

.deleteImage:hover {
  color: red;
}

@media only screen and (max-width: 1087px) {
  .div-room {
    width: 70% !important;
  }
}

@media only screen and (max-width: 933px) {
  .div-room {
    width: 80% !important;
  }
}

@media only screen and (max-width: 818px) {
  .div-room {
    width: 90% !important;
  }
}

@media only screen and (max-width: 725px) {
  .div-room {
    width: 100% !important;
  }
}

@media only screen and (max-width: 650px) {
  .building {
    width: 100% !important;
  }
}

@media only screen and (max-width: 972px) {
  .div-addBuilding,
  .div-building {
    width: 20% !important;
  }
}

.removeDetail:hover {
  color: red;
}

.detailsNumber {
  width: 5%;
}

@media only screen and (max-width: 972px) {
  .detailsNumber {
    width: 7%;
  }
}

.newDiv {
  border: gray solid 1px;
  width: 80%;
  border-radius: 3px;
  margin: auto;
  background-color: rgb(224, 224, 224);
}

.newDiv .grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  align-items: center;
}

.newDiv .grid-item {
  text-align: center;
  height: 100%;
  background-color: rgb(245, 245, 245);
}

.newDiv .grid-item:nth-child(even) {
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: white solid 1px;
}

.newTable {
  text-align: center;
  width: 100%;
  margin: auto;
  direction: rtl;
  height: 100%;
}

.newTable table,
td {
  border: 1px solid white !important;
  border-collapse: collapse;
}
.newTable td {
  width: 50%;
}

.newTable tr {
  background-color: #e9e8e8 !important;
}

.newTable tr:nth-child(even) {
  background-color: #ffffff !important;
}

.newDiv input {
  direction: rtl;
}

.newDiv .checkBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newDiv .size,
.livingRooms,
.parking {
  background-color: rgb(224, 224, 224) !important;
}

.newDiv .grid-item input {
  border: gray solid 0px;
  border: none;
  background-color: initial;
  width: 100%;
  border-radius: 0px;
  margin: 0px 0px;
  padding: 5px 0px;
}

.addFigure {
  width: 80%;
  margin: auto !important;
  text-align: center;
}

.addFigure button {
  width: fit-content;
  padding: 0 10px;
  background-color: #2d6a4f;
  margin: 1% auto;
  font-size: 21px;
}

.addFigure button:hover {
  background-color: #2d6a4f;
  color: white;
}

.deleteFigure {
  width: fit-content;
  padding: 0 10px;
  margin: 1% auto;
  text-align: center;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: initial;
}
.deleteFigure:hover {
  background-color: red;
  color: white;
}

.classInfo {
  width: 100%;
  margin: auto;
}

.classInfo .grid-item {
  text-align: center;
  height: 100%;
  background-color: rgb(245, 245, 245);
}

.classInfo .classRooms,
.classImage {
  background-color: rgb(224, 224, 224) !important;
}

.classState .form-control,
.classRooms .form-control {
  border-radius: 0px !important;
  height: 100%;
  text-align: center;
}

.inputImageDiv {
  width: 80%;
  margin: auto;
}

.addImageToImages {
  width: 80%;
  margin: auto;
  text-align: center;
  /* border-radius: 5px;
  border: solid gray 1px; */
  padding: 5px;
}

.addImageToImages {
  width: 80%;
  margin: auto !important;
  text-align: center;
}

.addImageToImages button {
  width: fit-content;
  padding: 0 10px;
  background-color: #2d6a4f;
  margin: 1% auto;
  font-size: 21px;
}

.addImageToImages button:hover {
  background-color: #2d6a4f;
  color: white;
}

.newDiv .size {
  width: 100% !important;
  padding: 5px 0 !important;
  height: unset !important;
}
