.bookingDataEdite .addBookingTab {
  width: 100%;
}

/* @media only screen and (min-width: 959px) {
  .bookingDataEdite .MuiBox-root-72 {
    top: 55%;
    width: 60%;
    height: auto;
  }
} */

@media only screen and (min-width: 1200px) {
  .bookingDataEdite .MuiBox-root-78 {
    top: 55%;
    width: 60% !important;
    height: auto;
  }
}

@media only screen and (max-width: 685px) {
  .bookingDataEdite .MuiTabPanel-root {
    padding: 24px 3px;
  }
}

@media only screen and (max-width: 570px) {
  .bookingDataEdite .addBookingTab {
    width: 100%;
  }
}

@media only screen and (max-width: 510px) {
  .bookingDataEdite .MuiBox-root-72 {
    padding: 16px 15px 24px;
  }
}

@media only screen and (max-width: 480px) {
  .bookingDataEdite .MuiBox-root-348 {
    width: 90%;
  }
}

@media only screen and (max-width: 470px) {
  .bookingDataEdite .MuiTabPanel-root {
    padding: 0px !important;
  }
}

@media only screen and (max-width: 460px) {
  .bookingDataEdite .MuiBox-root-348 {
    width: 100%;
  }
}

@media only screen and (max-width: 426px) {
  .bookingDataEdite .MuiBox-root-72 {
    width: 95% !important;
  }
}

@media only screen and (max-width: 410px) {
  .bookingDataEdite .MuiBox-root-348 {
    padding: 0;
  }
}

@media only screen and (max-width: 390px) {
  .bookingDataEdite .MuiBox-root-72 {
    width: 99% !important;
  }
  .bookingDataEdite .MuiBox-root-72 {
    padding: 16px 3px 24px;
  }
}

@media only screen and (max-width: 380px) {
  .bookingDataEdite .MuiTabPanel-root {
    padding: 18px 10px !important;
  }
}

.bookingDataEdite .MuiInputLabel-filled.MuiInputLabel-shrink {
  padding-right: 10px;
  padding-top: 5px;
}

.bookingDataEdite .MuiInputLabel-formControl {
  right: 0;
}

.save {
  background-color: green !important;
}

.cancel {
  background-color: red !important;
}

.bookingData .appartmentOnFloor {
  width: 20%;
}
.bookingData .floor {
  width: 20%;
}

.bookingData .floor .MuiSelect-select.MuiSelect-select,
.bookingData .appartmentOnFloor .MuiSelect-select.MuiSelect-select {
  text-align: center !important;
}

@media only screen and (max-width: 860px) {
  .bookingData .appartmentNumber {
    width: 100%;
  }
  .bookingData .floor {
    width: 47%;
  }
  .bookingData .appartmentOnFloor {
    width: 47%;
  }
}

@media only screen and (max-width: 550px) {
  .bookingData .sizeField {
    width: 60%;
  }
}

@media only screen and (max-width: 500px) {
  .bookingData .depositReturn {
    width: 100%;
  }

  .bookingData .price {
    width: 35%;
  }

  .bookingData .VATState {
    width: 65%;
  }
  .bookingData .VAT {
    width: 100%;
  }

  .bookingData .total {
    width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  .bookingData .price {
    width: 40%;
  }

  .bookingData .VATState {
    width: 60%;
  }
}

@media only screen and (max-width: 400px) {
  .bookingData .deposit {
    width: 60%;
  }
  .bookingData .appartmentState {
    width: 40%;
  }

  .bookingData .VATState {
    width: 100%;
  }

  .bookingData .price {
    width: 100%;
  }
}

/* .bookingDataEdite {
  overflow: scroll;
} */

.bookingINFOAlwaqil {
  height: 85%;
  overflow-y: scroll;
}

.mainBoxINFOAlwaqil {
  /* top: 55%; */
  /* height: 75%; */
  /* max-width: 950px !important; */
}
